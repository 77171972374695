<template>
  <div class="dev-container">
    <p>Development in Progress</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PSA",
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
  },
  activated() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.dev-container {
  border-radius: 10px;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  color: #49464f;
}
</style>
